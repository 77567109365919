import {
	styled,
	S_board_title,
	S_board_text,
	S_board_column,
	S_text_link,
	style_default_board,
} from "../../default_styles";

export const _about_board = ({}) => {
	return (
		<div>
			<S_board_column>
				<S_board_title>About Us</S_board_title>
				<S_board_text>
					<p>
						Sugargenix LLC is a software company, creating software to bring joy into peoples lives.
					</p>
					<p>
						Having fun, making the fun.
					</p>
				</S_board_text>
			</S_board_column>
			<S_board_column>
				<S_board_title>Follow Us</S_board_title>
				<S_board_text>
					<p>
						Stay tuned and check the news board at{" "}
						<S_text_link to="/home">home</S_text_link> for updates and announcements.
					</p>
					<S_board_text>
						Twitter:{" "}
						<S_text_link to="https://twitter.com/sugargenix?lang=en">
							@sugargenix
						</S_text_link>
					</S_board_text>
					<S_board_text>
						BlueSky:{" "}
						<S_text_link to="https://bsky.app/profile/sugargenix.com">
							@sugargenix.com
						</S_text_link>
					</S_board_text>
					<S_board_text>
						Discord:{" "}
						<S_text_link to="https://discord.gg/ejGtPwa2WG">Sugargenix</S_text_link>
					</S_board_text>
					<S_board_text>
						Facebook:{" "}
						<S_text_link to="https://www.facebook.com/Sugargenix">
							@Sugargenix
						</S_text_link>
					</S_board_text>
					<p>
						Join our free tier over at{" "}
						<S_text_link to="https://www.patreon.com/sugargenix">Patreon</S_text_link>
						, your support is greatly appreciated!
					</p>
				</S_board_text>
			</S_board_column>
			<_board_smaller>
				<S_board_text>
					A bit of Pixie dust, a byte of sugar and hope to make your day that much
					sweeter.
				</S_board_text>
			</_board_smaller>
		</div>
	);
};

const _board_smaller = styled.div`
	${style_default_board};

	width: max(40vw, 80px);
`;
