import { E_page } from "../../components/comp_page";
import { E_date_mark } from "../../components/comp_news_datemark";

import {
	styled,
	S_board_column,
	S_board_title,
	S_board_text,
	style_default_board_size,
	style_default_border,
	style_default_board_shadow
} from "../../default_styles";

import bottom_banner from "../../assets/banner_art.gif";

// [TODO] [PAGE_INCOMPLETE] Fix: sizing

export const E_page_hidden = ({}) => {
	return (
		<E_page>
			<_board_hidden>
				<_board>
					<S_board_text>
						<p>In the making!</p>
						<p>One byte at a time.</p>
					</S_board_text>
					<_promo_gif src={bottom_banner}></_promo_gif>
				</_board>
			</_board_hidden>

			<E_date_mark created={"2025-03-01"}></E_date_mark>
			<S_board_column>
				<S_board_title>Secret Page</S_board_title>
				<_special_text>
					This page is reserved for super secret news. We are glad you found it!
				</_special_text>
			</S_board_column>
		</E_page>
	);
};

const _special_text = styled(S_board_text)`
	font-size: min(calc(6px + 1.6vw), 20px);

	background: ${(props) => props.theme.style_gradient_multicolor};
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-style: normal;
`;
const _promo_gif = styled.img`
	width: 100%;
	position: absolute;
	bottom: -2px;

	pointer-events: none;
`;

const _board_hidden = styled.div`
	display: flex;
	${style_default_board_size}

	${style_default_border}
	${style_default_board_shadow}

	background: ${(props) => props.theme.style_gradient_pink};

	margin: auto;
	margin-bottom: 25px;
	margin-top: min(16vw, 130px);
	flex-direction: column;
`;

const _board = styled.span`
	position: relative;
	padding-top: 25px;

	aspect-ratio: 2.5 / 1;
	border-radius: 17px;

	overflow: hidden;
`;