import { styled, style_default_board_shadow, S_board_title, style_text_base } from "../default_styles";
import news_image from "../news/news_001_center_cut.png"
import patreon_image from "../assets/legendary_celestial.webp"

import { Link } from 'react-router-dom';

export const E_wheel_news_001 = ({}) => {
	return <>
		<S_board_title>Website Unveiled</S_board_title>
		<_wheel_card_container_inner>

			<_left_side>
				<_description_small>We are thrilled to introduce Sugargenix, a game studio company, sweeter than nectar and fiercer than the ocean waves.</_description_small>
				<_description>
					<p>We are thrilled to introduce Sugargenix, a game studio company, sweeter than nectar and fiercer than the ocean waves!</p>
					<p>Help us reach the stars!</p>
				</_description>
			</_left_side>
			<_right_side>
			</_right_side>
		</_wheel_card_container_inner>
	</>
}

export const E_wheel_news_002 = ({}) => {
	return <>
		<S_board_title>Find us at Patreon</S_board_title>
		<_wheel_card_container_inner>

			<_left_side>
				<_description_small>Support us over at Patreon! Access secret Discord Channels, see behind the scenes and more!</_description_small>
				<_description>
					<p>Come join us at Patreon!</p>
					<p>Get exclusive content even as a free tier Patreon.</p>
					<p>Access secret Discord Channels, see behind the scenes and more!</p>
				</_description>
			</_left_side>
			<_right_side3>
			</_right_side3>
		</_wheel_card_container_inner>
	</>
}

export const E_wheel_news_003 = ({}) => {
	return <>
		<S_board_title>Under construction</S_board_title>
		<_wheel_card_container_inner>

			<_right_side2>
			</_right_side2>
		</_wheel_card_container_inner>
	</>
}

export const E_wheel_card_cont = ({ activeCard, child, href_url }) => {
	let path = window.location.pathname.slice(1);

	return <_wheel_card_container activeC={activeCard} to={href_url} active={path == "/news/001"}>
		{child}
	</_wheel_card_container>
}

const _wheel_card_container = styled(Link)`
	height: 100%;

	padding: 8px 18px 0px;
	padding-left: min(calc(4px + 4vw), 32px);
	box-sizing: border-box;

	/* display: flex; */
	flex-direction: column;
	text-decoration: none;

	display: ${(props) => (props.activeC == true ? "flex" : "none")};
	/* display: flex; */
`

const _wheel_card_container_inner = styled.div`
	height: 100%;

	display: flex;
`

const _left_side = styled.div`
	width: 38%;
	height: 100%;
	margin-right: 14px;

	display: flex;
	flex-direction: column;
	/* justify-content: center; */
	/* border: 1px solid purple; */
	`

const _description_small = styled.div`
	${style_text_base}
	margin-top: 10px;

	font-size: min(calc(4px + 1.7vw), 20px);
	line-height: min(calc(4px + 1.7vw), 20px);

	padding: 0px 0px;
	text-align: start;

	@media (min-width: ${'451px'}) {
		display: none !important;
	}
`

const _description = styled.div`
	${style_text_base}
	font-size: min(calc(5px + 2vw), 25px);
	line-height: min(calc(5px + 2vw), 25px);

	text-align: start;

	@media (max-width: ${'450px'}) {
		display: none !important;
	}
`

const _right_side = styled.div`
	width: 62%;

	background-image: url(${news_image});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;

	${style_default_board_shadow}
`

const _right_side3 = styled.div`
	width: 62%;

	background-image: url(${patreon_image});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
`

const _right_side2 = styled.div`
	width: 52%;
	height: 70%;

	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	border-radius: 20px;
	border: 3px solid white;
	margin: auto;

	${style_default_board_shadow}
`

const _right_side_mympheus = styled.div`
	width: 100%;
	height: 100%;

	background-size: contain;
	background-position: 45% 50%;
	background-repeat: no-repeat;

	${style_default_board_shadow}
`