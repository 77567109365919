import { E_date_mark } from "../components/comp_news_datemark";
import { E_page } from "../components/comp_page";
import {
	styled,
	S_board_column,
	S_board_title,
	S_board_text,
	S_paragraph_compact,
	style_default_board_size,
	style_default_border,
	style_default_board_shadow,
	S_text_link,
} from "../default_styles";

import sky_image from "./starry_night.png"

export const E_news_001 = ({}) => {
	return (
		<E_page>
			<_img_cont_2/>

			<E_date_mark created={"2025-02-14"} edited={"2025-03-27"}></E_date_mark>

			<S_board_column>
				<S_board_title>Sugargenix</S_board_title>
				<S_board_text>
					<p>
						Sweeter than nectar, fiercer than ocean waves, this is Sugargenix, an indie games company.
					</p>
					<p>
						We strive for excellence, aim for the stars, and hope to capture your hearts.
					</p>
					<p>
						We will carve our own success like the rivers carved the canyons -- so stay tuned for more updates, and welcome to his exciting journey!
					</p>
					<p>Having fun in making the fun.</p>
				</S_board_text>
			</S_board_column>

			<S_board_column>
				<S_board_title>Help us Reach the Stars</S_board_title>
				<S_board_text>
					<p></p>
				<_mini_board>
					<S_board_text style={{ fontSize: "min(calc(5px + 1.5vw), 20px)" }}>
						<S_paragraph_compact>“And he gazed upon the night sky and wondered, ‘Surely it just a matter of building a big enough tower.’”</S_paragraph_compact>
					</S_board_text>
				</_mini_board>
					<p>
						There are two main ways you can help us reach the stars:
					</p>
					<_list>
						<li>Share what you like about us to others.</li>
						<li>Visit our Patreon: Subscribe to our <S_text_link to="https://patreon.com/sugargenix">free tier</S_text_link> where we post free game development material.</li>
					</_list>
					<p>
						We appreciate all your support! What is the mighty sea if not the sum of many individual raindrops.
					</p>
				</S_board_text>
			</S_board_column>

			<S_board_column>
				<S_board_title>Connect with Us</S_board_title>
				<S_board_text>
					<p>
						We appreciate your feedback and suggestion on how we may further improve your experience.
					</p>
					<p>Email feedback to: feedback@sugargenix.com</p>
					<p>Follow us on Twitter (<S_text_link to="https://x.com/sugargenix?lang=en">@sugargenix</S_text_link>)</p>
					<p>or BlueSky (<S_text_link to="https://bsky.app/profile/sugargenix.com">@sugargenix.com</S_text_link>).</p>
				</S_board_text>
			</S_board_column>

			<_mini_board style={{ width: "min(30vw, 500px)" }}>
				<S_board_text style={{ fontSize: "min(calc(5px + 1.5vw), 20px)" }}>
					<S_paragraph_compact>Thank you, and go be epic!</S_paragraph_compact>
				</S_board_text>
			</_mini_board>

		</E_page>
	);
};

const _img_cont_2 = styled.div`
	${style_default_board_shadow}
	background-size: cover;

	margin: 0px auto 20px;

	background-image: url(${sky_image});
	background-size: contain;
	background-repeat: no-repeat;

	width: min(90vw, 800px);
	aspect-ratio: 2046/800;
	${style_default_border}
`;

const _mini_board = styled.div`
	${style_default_board_size}

	${style_default_border}
	${style_default_board_shadow}
	background: ${(props) => props.theme.style_main_color};
	background: ${(props) => props.theme.style_gradient_pink};
	background-size: cover;

	margin: auto;
	margin-bottom: 16px;

	background-image: ${(props) => props.theme.style_gradient_blue};
	padding: 0px;

	width: min(70vw, 500px);
	height: max-content;
`;

const _list = styled.ol`
	margin: 0px 40px 4%;
	text-align: start;
	font-size: min(calc(6px + 2.1vw), 25px);
`
