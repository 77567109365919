import { E_date_mark } from "../components/comp_news_datemark";
import { E_page } from "../components/comp_page";
import {
	styled,
	S_board_column,
	S_board_title,
	S_board_text,
	S_paragraph_compact,
	style_default_board_size,
	style_default_border,
	style_default_board_shadow,
	S_text_link,
} from "../default_styles";

import sky_image from "./starry_night.png"

export const E_news_002 = ({}) => {
	return (
		<E_page>

			<S_board_column>
				<S_board_title>Join our Patreon</S_board_title>
				<S_board_text>
					<p>
						You can find us at <S_text_link to="https://www.patreon.com/c/sugargenix">Patreon</S_text_link>!
					</p>
					<p>
						We hope to make Patreon a hub for our supporters. All your support counts, whether you are
						a free tier member, or have taken a pledge; we hope to have something for you. What is the mighty sea if not the sum of many individual raindrops.
					</p>
				</S_board_text>
			</S_board_column>
			<E_date_mark created={"2025-03-27"}></E_date_mark>

			<S_board_column>
				<S_board_title>Patreon Benefits</S_board_title>
				<S_board_text>
					<p>
						Patreon members get access to exclusive <S_text_link to="https://discord.gg/ejGtPwa2WG">Discord</S_text_link> channels, where they have a direct line of influence for the work we produce.
					</p>
					<p>
						Members also get access to more Patreon content including more downloadable content.
					</p>
				</S_board_text>
			</S_board_column>

			<S_board_column>
				<S_board_text>
					<p>
						We hope Patreon can help us make a different type of company, one filled with magic and wonder.
					</p>
					<p>
						Along the way we hope to build a great community and pick up some awesome friends!
					</p>
					<p>
						Let's adventure <S_text_link to="https://www.patreon.com/c/sugargenix">together</S_text_link>!
					</p>
				</S_board_text>
			</S_board_column>

		</E_page>
	);
};

const _img_cont_2 = styled.div`
	${style_default_board_shadow}
	background-size: cover;

	margin: 0px auto 20px;

	background-image: url(${sky_image});
	background-size: contain;
	background-repeat: no-repeat;

	width: min(90vw, 800px);
	aspect-ratio: 2046/800;
	${style_default_border}
`;

const _mini_board = styled.div`
	${style_default_board_size}

	${style_default_border}
	${style_default_board_shadow}
	background: ${(props) => props.theme.style_main_color};
	background: ${(props) => props.theme.style_gradient_pink};
	background-size: cover;

	margin: auto;
	margin-bottom: 16px;

	background-image: ${(props) => props.theme.style_gradient_blue};
	padding: 0px;

	width: min(70vw, 500px);
	height: max-content;
`;

const _list = styled.ol`
	margin: 0px 40px 4%;
	text-align: start;
	font-size: min(calc(6px + 2.1vw), 25px);
`
