import { E_page } from "../../components/comp_page";
import { E_team_board } from "./team_board";
import { styled, S_board_column, S_board_text, S_board_title, S_text_link } from "../../default_styles";

import bottom_banner from "../../assets/banner_art.gif";

export const E_page_team = ({}) => {
	return (
		<E_page>
			<E_team_board></E_team_board>
			<S_board_column>
				<S_board_title>Connect with Us</S_board_title>
				<S_board_text>
					<p>
						Follow us on{" "}
						<S_text_link to="https://twitter.com/sugargenix?lang=en">
							Twitter
						</S_text_link>{" "}
						or{" "}
						<S_text_link to="https://bsky.app/profile/sugargenix.com">
							BlueSky
						</S_text_link>.
						We appreciate any feedback you may have on how we may further
						improve your experience on our website.
					</p>
					<p>Email feedback to: <S_text_link to="mailto:feedback@sugargenix.com">feedback@sugargenix.com</S_text_link></p>
					<p>
						Stay tuned and check out the news board at home for updates and
						announcements.
					</p>
				</S_board_text>
			</S_board_column>
			<S_board_column style={{ marginBottom: "100px" }}>
				<S_board_text>
					<p>
						Come chat with us directly over on our <S_text_link to="https://discord.com/invite/ejGtPwa2WG">Discord</S_text_link>.
					</p>
					<p>
						We post free game dev material on Discord and also our free tier <S_text_link to="https://www.patreon.com/c/sugargenix">Patreon</S_text_link>.
					</p>
				</S_board_text>
			</S_board_column>
			<_container>
				<_promo_gif src={bottom_banner}></_promo_gif>
			</_container>
		</E_page>
	);
};

const _container = styled.div`
	width: 100vw;
	display: flex;

	bottom: 64px;
	position: fixed;

	@media (min-width: 769px) {
		display: none !important;
	}
`;

const _promo_gif = styled.img`
	width: 100%;
`;
