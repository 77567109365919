import { _team_member } from "./team_member";

import kevin_gif from "../../assets/team_kevin.gif";

import {
	styled,
	S_board_title,
	S_board_text,
	S_board_column,
	S_text_link,
} from "../../default_styles";

export const E_team_board = ({}) => {
	return (
		<S_board_column>
			<S_board_title>Team</S_board_title>
			<_team_container>
				<_team_member
					member={kevin_gif}
					name={"Kevin Mira"}
					text={`
Computer Wizard.
1 bit Pyrotechnic, 128 bytes Programmer.`}
				></_team_member>
			</_team_container>
		</S_board_column>
	);
};

const _team_container = styled.div`
	width: 100%;

	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-evenly;
`;
