import background_hologram from "../assets/background_hologram.png";
import { styled } from "../default_styles";

import { keyframes } from "styled-components";

export const E_background_default = ({}) => {
	return (
		<_background>
			<_background_holograms rel="preload" />
		</_background>
	);
};

const _background = styled.div`
	z-index: -1;
	position: fixed;

	background: linear-gradient(
			158deg,
			#e6e6e633 -80%,
			${(props) => props.theme.style_darker_secondary_color + "33"} 100%
		),
		linear-gradient(
			158deg,
			${(props) => props.theme.style_neutral_light_color} -80%,
			${(props) => props.theme.style_main_color} 200%
		);
	filter: brightness(120%);
	background-size: 100% 100%;

	width: 200%;
	height: 100%;
`;

const slide = keyframes`
	0% { transform: translate3d(0, 0, 0); }
	100% { transform: translate3d(-720px, -720px, 0); }

`;

const _background_holograms = styled.div`
	opacity: 10%;
	background-image: url(${background_hologram});
	animation: ${slide} 450s linear infinite;
	background-size: 720px 360px;
	background-repeat: repeat;
	width: 200%;
	height: 400%;
`;
