import { Routes, Route } from "react-router-dom";

import { E_news_001 } from "./news_001_hello_world";
import { E_news_002 } from "./news_002_patreon";

import { E_page_not_found } from "../pages/not_found";

export const E_news = ({}) => {
	return (
		<Routes>

			<Route path="/001" element={<E_news_001 />} />
			<Route path="/hello-world" element={<E_news_001 />} />

			<Route path="/002" element={<E_news_002 />} />
			<Route path="/patreon" element={<E_news_002 />} />

			<Route path="/*" element={<E_page_not_found />} />
		</Routes>
	);
};
